if (process.env.NODE_ENV !== "development") {
  import("@sentry/browser").then((Sentry) => {
    const sentry_dsn = process.env.REACT_APP_SENTRY_DSN || "";
    if (sentry_dsn) {
      Sentry.init({
        dsn: sentry_dsn,
        environment: process.env.NODE_ENV,
        denyUrls: ["/play"],
      });
    }
  });
}

import React, { lazy, Suspense } from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import faker from "faker";

import "resize-observer-polyfill/dist/ResizeObserver.global";
import "react-native-gesture-handler";
import "./debugGlobals";
import "./yupMethods";
import "./index.css";

import { disableIOSZoom } from "@smartrent/utils";
import { ThemeProvider } from "@smartrent/ui";
import { alloy, smartrent } from "@smartrent/tokens";

import FullScreenLoader from "./components/FullScreenLoader";
import { worker } from "./mocks/browser";
import { setupApi } from "./api";

import type { ThemeMode } from "@smartrent/ui";

faker.seed(1);
disableIOSZoom();
worker.start();

setupApi();

const App = lazy(() => import("./App"));

const colorScheme =
  (localStorage.getItem("colorScheme") as ThemeMode) ?? "dark";
const themeName = localStorage.getItem("themeName") ?? "smartrent";
const theme = themeName === "smartrent" ? smartrent : alloy;

render(
  <ThemeProvider initialMode={colorScheme} theme={theme}>
    <Suspense fallback={<FullScreenLoader />}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Suspense>
  </ThemeProvider>,
  document.getElementById("root")
);
